<template>
    <!--    실물 구매 레이아웃-->
    <div class="mypage_auction_exhibit_wrap">
        <!--    레이아웃-->
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style="table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="94px" />
                <col width="130px" />
                <col width="240px" />
                <col width="240px" />
                <col width="130px" />
                <col width="190px" />
                <col width="170px" />
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('item')}} {{$t('subject')}}</th>
                <th colspan="1">{{$t('competition')}} {{$t('subject')}}</th>
                <th colspan="1">{{$t('awards')}}</th>
                <th colspan="1">{{$t('awards')}}{{$t('dateTime')}}</th>
                <th colspan="1">{{$t('download')}}</th>
            </tr>
            <tr v-for="(competition, index) in competitionData" :key="`competition-my-${competition.c_idx}`"
                @click="movePage(`/item/${competition.Item.i_sku}`)" class="competition_list cursor">
                <td>{{tableIdx(index)}}</td>  <!-- No -->
                <td><img :src="returnImage(competition.Item.ItemFile.Item,78,49)" width="78" height="49"/></td>
                <td class="t_subject">{{ competition.Item.i_name }}</td>  <!-- 아이템명 -->
                <td class="t_subject">{{ competition.c_title }}</td><!--        공모전명 -->

                <td>{{competition.ci_rank}}{{ $t('th') }}</td> <!-- 수상 -->

                <td>{{ returnDateTime(competition.c_release_time) }}</td>  <!-- 일시 -->
                <!--        다운로드 -->
                <td >
                    <div v-if="checkDownloadDate(competition)"> - </div>
                    <div  v-else  @click="awsS3Download('original',competition.Item.ItemFile.NFT[0],competition.Item.i_idx,true)">
                        <competition-release-time :competition-data="competition"></competition-release-time>
                    </div>

                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import CompetitionReleaseTime from "@/components/competition/CompetitionReleaseTime";
import fileMixins from "@/mixins/fileMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "CompetitionListMyLayout",
    mixins: [alertMixins, imageOption, fileMixins, awsS3FileDownload, priceMixins],
    components: {
        CompetitionReleaseTime,
        CompetitionDateTime
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY-MM-DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY-MM-DD')
            return `${s_time} ~ <br/> ${e_time}`
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY-MM-DD HH:mm')
            return r_date;
        },
        returnStatus(status) {
            switch (status) {
                case 0 :{
                    return this.$t('scheduled_to_proceed')
                }
                case 1 :{
                    return this.$t('in_process2')
                }
                case 2 :{
                    return this.$t('under_review')
                }
                case 3 :{
                    return this.$t('p_completed')
                }
                default :{
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        checkDownloadDate(competition){
            let current = this.$moment()
            let release = this.$moment(competition.c_release_time).add(10,'d')
            let diff = current.diff(release, 'day')
            return diff > 0
        },
        awsS3Download(type, name, key) {
            this.runDownloadFileInfo(type, name, key).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
