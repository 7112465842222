<template>
    <button class="endDate">{{returnDiffTime(competitionData)}}</button>
</template>

<script>
let timeInterval = null;
export default {
    name: "CompetitionReleaseTime",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            timer: null,
            diffDay: 0,
            diffTime: 0,
            resultType: false,
            currentTime:'',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    computed: {

    },
    methods: {

        returnDiffTime(competition) {
            return this.reduceTime(competition)
        },
        getCurrentTime() {
            this.currentTime = this.$moment()
        },
        reduceTime(competition){
            let current = this.currentTime
            let release = this.$moment(competition.c_release_time).add(10,'d')

            return release.format('YY-MM-DD')

            let diff = current.diff(release, 'day')
            if (diff > -1) {
                let s_diff = current.diff(release, 'second')
                diff =  s_diff > 0 ? 0 : Math.abs(s_diff)
            } else {
                return `D-${Math.abs(diff)}`
            }
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min} : ${sec}`
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
